import { secondDatabase } from "../../config/firebase";

const getFirmId = async () => {
  let tempProjects = [];
  let userName = "";

  const localStorageData = JSON.parse(localStorage.getItem("User"));
  const tempFirmId = localStorageData.firmId;

  if (!tempFirmId) {
    console.error("Firm ID not found in local storage.");
    return [tempProjects, userName];
  }

  const useRef = secondDatabase.ref(`userDetails/${tempFirmId}`);

  // Wrap Firebase data fetch in a Promise
  return new Promise((resolve, reject) => {
    useRef.once(
      "value",
      (snapshot) => {
        const data = snapshot.val();
        console.log('getFirmId data : ', data)
        let firmId = data.personalDetails.firmDetails.firmDetailsID || '';
        // let firmId = '1198347';
        resolve(firmId);
      },
      (error) => {
        console.error("Error fetching data:", error);
        reject(error);
      }
    );
  });
};


export default getFirmId;
