import { secondDatabase } from "../../config/firebase";

const fetchMaterial = async (firmId, boq) => {
  // write the code here
  const useRef = secondDatabase.ref(`${firmId}/projectMasterFile/materials/`);

  return new Promise((resolve, reject) => {
    useRef.once(
      "value",
      (snapshot) => {
        const data = snapshot.val();
        let newData = [];
        if(data !== null){
          newData = Object.values(data)
        }
        if(boq){
          newData = newData.map(({ description, uom, ...rest }) => ({
            specification: description,
            unit: uom,
            ...rest,
          }));
        }
        resolve(newData);
      },
      (error) => {
        console.error("Error fetching data:", error);
        reject(error);
      }
    );
  });
};

export default fetchMaterial;
