import { secondDatabase } from "../../config/firebase";

const dealMaterialRequest = async (requestData, selectedItems, selectedProject) => {

  const localStorageData = JSON.parse(localStorage.getItem("User"));

  // selectedItems.forEach(({ key, quantity }) => {
  //   console.log('checkItems key : ', key)
  //   if (requestData.items[key]) {
  //     requestData.items[key].quantity = quantity;
  //   }
  // });

  let quantityArray = [];

  selectedItems.forEach((selectedItem) => {
    Object.keys(requestData.items).forEach((key) => {
      const requestItem = requestData.items[key];
      if (requestItem.OrderId === selectedItem.OrderId) {
        requestItem.quantity = selectedItem.quantity;
        requestItem.checked = false;
        quantityArray.push(() => Number(selectedItem.quantity));
      }else{
        quantityArray.push(0);
      }
    });
  });

  let timestamp = new Date().getTime();

  requestData["requestHistory"][timestamp] = {
    id: timestamp,
    name: localStorageData.userName,
    remark: "",
    status: "Released",
    quantities: quantityArray || [],
  };

  console.log('dealMaterialRequest selectedProject.firmDetailsID : ', selectedProject.firmDetailsID)
  console.log('dealMaterialRequest selectedProject.projectID : ', selectedProject.projectID)
  console.log('dealMaterialRequest requestData.id : ', requestData.id)
  console.log('dealMaterialRequest requestData : ', requestData)

  await secondDatabase.ref(`${selectedProject.firmDetailsID}/MRNRequest/${selectedProject.projectID}/${requestData.id}/`).set(requestData);

};

export default dealMaterialRequest;
