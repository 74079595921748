/* eslint-disable import/no-anonymous-default-export */
export default (data) => {
  let status = "BOQ";
  const { rooms } = data;
  rooms &&
    rooms.forEach((room, roomIndex) => {
      const { Units } = room;
      Units.forEach((Unit, unitIndex) => {
        const { Components } = Unit;
        if (Components) {
          Components.forEach((component, componentIndex) => {
            const { Material, Work } = component;
            Material && Material.forEach((material, materialIndex) => {
              if (material.status === "ordered") status = "Purchase";
            });
            Work && Work.forEach((work, workIndex) => {
              if (work.status === "ordered") status = "Purchase";
            });
          });
        }
      });
    });
  return status;
};
