import { secondDatabase } from "../../../config/firebase";

const fetchData = async (firmId, projectId, projectName) => {
  // Return a Promise to handle async behavior correctly
  return new Promise((resolve, reject) => {
    const useRef = secondDatabase.ref(
      `${firmId}/projects/${projectId}/records/allInward/`
    );

    // Once the data is fetched from Firebase, handle the response
    useRef.once(
      "value",
      async (snapshot) => {
        
        let data = snapshot.val();

        if (data !== null) {
          try {
            // Wait for the inventory conversion to complete
            let returnData = Object.values(data);
            
            const updatedData = returnData.map((item) => ({
              ...item, // Spread the existing properties
              projectName: projectName, // Add the new key-value pair
            }));

            // return returnData;
            resolve(updatedData); // Resolve the Promise with the processed data
          } catch (error) {
            reject(error); // Reject the Promise if an error occurs during conversion
          }
        } else {
          resolve([]); // Resolve with an empty array if no data is found
        }
      },
      (error) => {
        reject(error); // Reject the Promise if Firebase request fails
      }
    );
  });
};

const generateInwardData = async (projects) => {
  // write the code here
  if (!Array.isArray(projects) && projects !== null) {
    try {
      // Wait for the fetchData promise to resolve and return the result
      const result = await fetchData(
        projects.firmDetailsID,
        projects.projectID,
        projects.projectName
      );
      console.log("Fetched result : ", result);
      // Return the fetched data
      return result.reverse();
    } catch (error) {
      console.error("Error fetching inventory data:", error);
      return []; // Return an empty array if an error occurs
    }
  } else {
    let allProjectData = [];
    for (let index = 0; index < projects.length; index++) {
      const project = projects[index];
      try {
        // Wait for the fetchData promise to resolve and return the result
        const result = await fetchData(
          project.firmDetailsID,
          project.projectID,
          project.projectName
        );
        // Return the fetched data
        allProjectData = [...allProjectData, ...result];
        
      } catch (error) {}
    }
    return allProjectData.reverse();
  }
};

export default generateInwardData;

// function generateInwardData(numItems) {
//   const imageUri =
//     "https://firebasestorage.googleapis.com/v0/b/staart-module.appspot.com/o/materialImages%2F1727083058149%2FIMG_5641.jpeg?alt=media&token=592a753c-2ddf-40d5-9e9b-af9f2a8cf4e2";

//   function randomString(length) {
//     const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
//     let result = "";
//     for (let i = 0; i < length; i++) {
//       result += characters.charAt(
//         Math.floor(Math.random() * characters.length)
//       );
//     }
//     return result;
//   }

//   function randomTimestamp() {
//     const now = new Date();
//     const daysAgo = Math.floor(Math.random() * 30); // random day within last 30 days
//     const randomDate = new Date(now);
//     randomDate.setDate(now.getDate() - daysAgo);
//     randomDate.setHours(Math.floor(Math.random() * 24));
//     randomDate.setMinutes(Math.floor(Math.random() * 60));
//     randomDate.setSeconds(Math.floor(Math.random() * 60));
//     return randomDate.toISOString();
//   }

//   function generateInvoiceItems() {
//     const itemCount = Math.floor(Math.random() * 15) + 1; // 1 to 15 items
//     const invoiceItems = {};

//     for (let i = 0; i < itemCount; i++) {
//       const itemId = `${randomString(10)}-${i}`;
//       invoiceItems[itemId] = {
//         amount: (Math.random() * 1000).toFixed(2),
//         category: "Fuel",
//         date: randomTimestamp(),
//         dateRequired: "Not provided",
//         description: "Diesel",
//         gst: (Math.random() * 20).toFixed(2),
//         historyId: randomString(10),
//         images: {
//           1727083060081: {
//             name: "IMG_5641.JPG",
//             type: "image/jpeg",
//             uri: imageUri,
//           },
//         },
//         itemID: parseInt(randomString(10), 10),
//         quantityAccepted: Math.floor(Math.random() * 100),
//         quantityOrdered: Math.floor(Math.random() * 100),
//         quantityReceived: Math.floor(Math.random() * 100),
//         quantityRejected: Math.floor(Math.random() * 10),
//         quantityRequired: Math.floor(Math.random() * 100),
//         rate: (Math.random() * 10).toFixed(2),
//         remark: "Used for site vehicles",
//         uom: "Ltr",
//       };
//     }

//     return invoiceItems;
//   }

//   function generateSingleInwardData() {
//     const invoiceId = randomString(10);
//     return {
//       fields: {
//         "01": { name: "Referral PO No.", value: `PO${randomString(5)}` },
//         "02": {
//           name: "Material Requisition Note (MRN)",
//           value: `MRN${randomString(5)}`,
//         },
//         "03": { name: "Vehicle No.", value: `VH${randomString(4)}` },
//         "04": {
//           "01": { name: "Invoice No.", value: `INV${randomString(3)}` },
//           "02": { name: "Invoice Date", value: randomTimestamp() },
//         },
//         "05": {
//           "01": { name: "Gate Pass No.", value: `GP${randomString(3)}` },
//           "02": { name: "Gate Pass Date", value: randomTimestamp() },
//         },
//         "06": {
//           "01": { name: "DC No.", value: `DC${randomString(3)}` },
//           "02": { name: "DC Date", value: randomTimestamp() },
//         },
//         "07": { name: "Supplier/Vendor", value: `Vendor ${randomString(4)}` },
//       },
//       invoices: {
//         [invoiceId]: {
//           invoiceItems: generateInvoiceItems(),
//           invoiceNumber: invoiceId,
//           timeStamp: randomTimestamp(),
//         },
//       },
//       poNumber: randomString(10),
//       referalPONumber: "-",
//       requestHistory: {
//         [randomString(10)]: {
//           associatedPersons: "",
//           doneBy: `Person ${randomString(4)}`,
//           forwardedTo: "",
//           noOfItem: 1,
//           remark: "",
//           status: "Updated",
//           timestamp: randomTimestamp(),
//         },
//       },
//       status: "released",
//       timeStamp: randomTimestamp(),
//       vendorName: `Vendor ${randomString(4)}`,
//     };
//   }

//   return Array.from({ length: numItems }, generateSingleInwardData);
// }
