// eslint-disable-next-line import/no-anonymous-default-export
export default (data) => {
  let total = 0;
  total = Number(total);
  const { rooms } = data;
  rooms &&
    Array.isArray(rooms) &&
    rooms.forEach((room, roomIndex) => {
      const { Units } = room;
      Units?.forEach((Unit, unitIndex) => {
        const { Components } = Unit;
        if (Components) {
          Components.forEach((component, componentIndex) => {
            const { Material, Work } = component;
            Material && Material.forEach((material, materialIndex) => {
              let { quantity, rate, gst } = material;
              if (
                quantity.trim().length > 0 &&
                rate.trim().length > 0 &&
                rate.trim().length > 0 &&
                !isNaN(quantity) &&
                !isNaN(rate) &&
                !isNaN(gst)
              ) {
                total =
                  total +
                  Number(
                    (
                      Math.round(
                        (quantity *
                          (material?.discountedRate &&
                          material.discountedRate !== "null"
                            ? material.discountedRate
                            : rate) +
                          quantity *
                            (material?.discountedRate &&
                            material.discountedRate !== "null"
                              ? material.discountedRate
                              : rate) *
                            (gst / 100)) *
                          100
                      ) / 100
                    ).toFixed(2)
                  );
              }
            });
            Work &&  Work.forEach((work, workIndex) => {
              let { quantity, rate, gst } = work;
              if (
                quantity.trim().length > 0 &&
                rate.trim().length > 0 &&
                rate.trim().length > 0 &&
                !isNaN(quantity) &&
                !isNaN(rate) &&
                !isNaN(gst)
              ) {
                total =
                  total +
                  Number(
                    (
                      Math.round(
                        (quantity *
                          (work?.discountedRate &&
                          work.discountedRate !== "null"
                            ? work.discountedRate
                            : rate) +
                          quantity *
                            (work?.discountedRate &&
                            work.discountedRate !== "null"
                              ? work.discountedRate
                              : rate) *
                            (gst / 100)) *
                          100
                      ) / 100
                    ).toFixed(2)
                  );
              }
            });
          });
        }
      });
    });
  return Math.round(total);
};
