import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AuthService from "../../../api/authService";
import { UserContext } from "../../../Context/UserContext";
import { DropDownMUI } from "../../../components";
import { toast } from "react-toast";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
}));

const Form = ({ handleClose, setAdminUsers, onAuthChanged }) => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [formError, setFormError] = useState({
    email: "",
    userName: "",
    contactNumber: "",
    accountType: "",
  });
  const [firm, setFirm] = useState(userContext.user.Firm);
  const [id, setId] = useState("");
  const [accountType, setAccountType] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const isEmailValid = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  const isContactNumberValid = (value) => /^\d{10}$/.test(value);
  const onSubmit = async () => {

    const authService = new AuthService();
    console.log("FORM DATA ", email, userName, contactNumber, accountType);

    if (!isEmailValid(email)) {
      setFormError((prev) => ({ ...prev, email: "Invalid email" }));
      return;
    } else {
      setFormError((prev) => ({ ...prev, email: "" }));
    }

    if (!isContactNumberValid(contactNumber)) {
      setFormError((prev) => ({
        ...prev,
        contactNumber: "Invalid contact number",
      }));
      return;
    } else {
      setFormError((prev) => ({ ...prev, contactNumber: "" }));
    }
    if (!accountType) {
      setFormError((prev) => ({
        ...prev,
        accountType: "Select a role",
      }));
      return;
    }

    if (
      email.trim().length > 0 &&
      userName.trim().length > 0 &&
      accountType.length > 0
    ) {
      try {

        const response = await authService.adminSignup(
          email.trim(),
          "",
          accountType,
          firm,
          userName.trim(),
          0,
          contactNumber,
          contactNumber
        );

        console.log("RES OF ADDING USER", response);

        if (response.status == 200) {
          toast.success("Registered Successfully");
        } else {
          alert("duplicate email");
        }

        setAdminUsers((prev) => [
          ...prev,
          {
            email: email.trim(),
            accountType,
            userName: userName.trim(),
            Firm: userContext.user.firm,
            active: "0",
            ContactNumber: contactNumber,
            AssignedProjects: null,
            adminId: new Date().getTime()
          },
        ]);
        handleClose();
      } catch (error) {
        toast.error("Error adding user to firm!");
        alert(error);
      }
    } else {
      alert("Please Fill All Details");
    }
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      
      <TextField
        label="email"
        variant="filled"
        type="email"
        required
        error={Boolean(formError.email)}
        helperText={formError.email}
        value={email}
        onChange={(e) => {
          const val = e.currentTarget.value;
          if (isEmailValid(val)) {
            setFormError((prev) => ({ ...prev, email: "" }));
          }
          setEmail(val);
        }}
      />


      <TextField label="Firm" variant="filled" required value={firm} disabled />

      <TextField
        label="userName"
        variant="filled"
        type="userName"
        required
        value={userName}
        onChange={(e) => {
          setUserName(e.currentTarget.value);
        }}
      />

      <TextField
        label="Contact Number"
        variant="filled"
        type="tel"
        required
        value={contactNumber}
        onChange={(e) => {
          const val = e.currentTarget.value;
          if (isContactNumberValid(val)) {
            setFormError((prev) => ({ ...prev, contactNumber: "" }));
          }
          setContactNumber(e.currentTarget.value);
        }}
        error={Boolean(formError.contactNumber)}
        helperText={formError.contactNumber}
      />

      <DropDownMUI
        placeholder={"Role"}
        disabeSorting={true}
        data={["Admin", "Project", "Finance", "BOQ"]} // match the strings ever
        value={accountType}
        onChange={(value) => {
          setAccountType(value);
          if (value) {
            setFormError((prev) => ({
              ...prev,
              accountType: "",
            }));
          }
        }}
        size={"small"}
        custStyles={{ width: "100%" }}
      />
      {formError.accountType && (
        <p
          style={{ color: "red", paddingLeft: "1em", alignSelf: "flex-start" }}
        >
          Select a role
        </p>
      )}
      <div>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => onSubmit()}
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

export default Form;
