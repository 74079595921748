import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

const handleExportExcelOutward = async (filteredData, project) => {
    const includeProjectName =
      typeof project === "object" && project !== null && "projectName" in project;
  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Outward Items");
  
    // Define headers
    const columns = [
      { header: "Request No.", key: "Request No.", width: 20 },
      { header: "Site Engineer", key: "Site Engineer", width: 20 },
      { header: "Work Progress", key: "Work Progress", width: 20 },
      { header: "Done By", key: "Done By", width: 20 },
      { header: "Status", key: "Status", width: 15 },
      { header: "Timestamp", key: "Timestamp", width: 20 },
      { header: "Item Category", key: "Item Category", width: 20 },
      { header: "Item Description", key: "Item Description", width: 30 },
      { header: "Quantity Issued", key: "Quantity Issued", width: 20 },
      { header: "Quantity Available", key: "Quantity Available", width: 20 },
      { header: "UOM", key: "UOM", width: 10 },
      { header: "Purpose", key: "Purpose", width: 30 },
      { header: "Item Image", key: "Item Image", width: 30 },
      { header: "Bills Image", key: "Bills Image", width: 30 },
    ];
  
    if (includeProjectName) {
      columns.unshift({
        header: "Project Name",
        key: "Project Name",
        width: 25,
      });
    }else{
      columns.unshift({
        header: "Project Name",
        key: "Project Name",
        width: 25,
      });
    }
  
    worksheet.columns = columns;
  
    // Style headers
    const headerRow = worksheet.getRow(1);
    headerRow.font = { bold: true };
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
  
    const assignBackgroundColorToRow = (row, color) => {
      row.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: color },
        };
      });
    };
  
    for (const entry of filteredData) {
      if (worksheet.rowCount > 1) {
        worksheet.addRow([]);
      }
  
      const baseRowData = {
        "Request No.": entry.requestNo || "Not provided",
        "Site Engineer": entry.siteEngineer || "Not provided",
        "Work Progress": entry.workProgress || "Not provided",
        "Done By": entry.requestHistory
          ? entry.requestHistory[Object.keys(entry.requestHistory || {}).pop()]?.doneBy || "Unknown"
          : "Unknown",
        "Status": entry.status || "Not provided",
        "Timestamp": entry.timestamp || "Not provided",
      };
  
      if (includeProjectName) {
        baseRowData["Project Name"] = project.projectName;
      }else{
        baseRowData["Project Name"] = entry.projectName;

      }
  
      const rootImages = entry.images ? Object.values(entry.images).filter((img) => img.url) : [];
  
      Object.values(entry.outwardItems).forEach((item, itemIndex) => {
        const rowData = itemIndex === 0 ? { ...baseRowData } : Object.fromEntries(Object.keys(baseRowData).map((key) => [key, "-"]));
  
        rowData["Item Category"] = item.category || "-";
        rowData["Item Description"] = item.description || "-";
        rowData["Quantity Available"] = item.quantityAvailable || "-";
        rowData["UOM"] = item.uom || "-";
  
        // Set Quantity Issued: prioritize quantityIssued, fallback to quantityAccepted if not available
        rowData["Quantity Issued"] = item.quantityIssued || item.quantityAccepted || "-";
  
        const purposes = Object.values(item.listOfPurpose || {})
          .map((purpose) => purpose.label || "-")
          .join(", ");
        rowData["Purpose"] = purposes || "No Purpose";
  
        // Handling item images
        const itemImages = item.images ? Object.values(item.images).filter((img) => img.uri || img.url) : [];
        if (itemImages.length > 0) {
          rowData["Item Image"] = itemImages.map((img, index) => ({
            text: `Image${index + 1}`,
            hyperlink: img.uri || img.url,
          }));
        } else {
          rowData["Item Image"] = "No Image";
        }
  
        // Handling root-level images (Bills Image)
        if (rootImages.length > 0) {
          rowData["Bills Image"] = rootImages.map((img, index) => ({
            text: `Image${index + 1}`,
            hyperlink: img.uri || img.url,
          }));
        } else {
          rowData["Bills Image"] = "No Image";
        }
  
        const mainRow = worksheet.addRow(rowData);
  
        if (itemIndex === 0) {
          assignBackgroundColorToRow(mainRow, "FFFFE699");
        }
  
        mainRow.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "left", wrapText: true };
          
          // Check if cell contains image links array
          if (Array.isArray(cell.value)) {
            // Format the links
            const formattedLinks = cell.value.map(link => ({
              text: link.text,
              hyperlink: link.hyperlink
            }));
            
            // Apply formatting to each link
            cell.value = formattedLinks[0];  // Set first link
            cell.font = { color: { argb: "FF0000FF" }, underline: true };
            
            // Add remaining links in adjacent cells below
            if (formattedLinks.length > 1) {
              const colNumber = cell.col;
              formattedLinks.slice(1).forEach((link, index) => {
                const newCell = worksheet.getCell(cell.row + index + 1, colNumber);
                newCell.value = link;
                newCell.font = { color: { argb: "FF0000FF" }, underline: true };
                newCell.alignment = { vertical: "middle", horizontal: "left", wrapText: true };
              });
            }
          }
        });
      });
    }
  
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${project.projectName ? project.projectName : 'Site'} Outward.xlsx`);
};

export default handleExportExcelOutward;