import { BorderColor } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ItemTableBottom from "./ItemTableBottom";
import ImageModal from "../Components/ImageModal";

const ItemsTable = ({
  userName, 
  itemData,
  project,
  projectName,
  invoices,
  onViewMore,
  onImageClick,
}) => {

  const [expanded, setExpanded] = useState(false);
  const items = Object.values(invoices).flatMap((invoice) =>
    Object.values(invoice.invoiceItems)
  );
  const [imageItems, setImageItems] = useState([]);
  const [displayImage, setDisplayImage] = useState(false);
  const visibleItems = expanded ? items : items.slice(0, 2);

  return (
    <div style={styles.container}>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.column}>Category</th>
            <th style={styles.column}>Item</th>
            <th style={styles.column}>Quantity</th>
            <th style={styles.column}>Unit</th>
            <th style={styles.column}>Remark</th>
            <th style={styles.column}>Images</th>
          </tr>
        </thead>
        <tbody>
          
          {visibleItems.map((item, index) => (
            <tr
              key={index}
              style={index % 2 === 0 ? styles.rowEven : styles.rowOdd}
            >
              <td style={styles.cell}>{item.category}</td>
              <td style={styles.cell}>{item.description}</td>
              <td style={styles.cell}>{item.quantityAccepted}</td>
              <td style={styles.cell}>{item.uom}</td>
              <td style={styles.cell}>{item.remark}</td>
              <td style={styles.cell}>
                {
                  item.images ? (
                    // Object.values(item.images).map((image, i) => (
                    <button
                      style={styles.button}
                      onClick={() => {
                        console.log('invoices : ', invoices)
                        setDisplayImage(true);
                        setImageItems(() => Object.values(item.images));
                      }}
                    >
                      View Image
                    </button>
                  ) : (
                    <button style={{...styles.button, ...{color: '#aaa'}}}>
                      No Images
                    </button>
                  )
                  // ))
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {displayImage && (
        <ImageModal
          images={imageItems}
          selectedImageIndex={0}
          onClose={() => {
            setDisplayImage(false);
            setImageItems([]);
          }}
        />
      )}

      <ItemTableBottom
        userName={userName}
        itemData={itemData}
        project={project}
        projectName={projectName}
        items={items}
        expanded={expanded}
        setExpanded={setExpanded}
      />
    </div>
  );
};

const styles = {
  container: {
    marginTop: "20px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    tableLayout: "fixed", // Ensures fixed-width columns
  },
  column: {
    width: "16.66%", // Equal width for all columns
    textAlign: "left",
    padding: "8px",
  },
  cell: {
    padding: "10px",
    textAlign: "left",
    wordWrap: "break-word", // Allows text wrapping for long content
    overflowWrap: "break-word", // Breaks long words if needed
    whiteSpace: "normal", // Allows text to wrap to the next line
  },
  rowEven: {
    backgroundColor: "#f1f1f1",
  },
  rowOdd: {
    backgroundColor: "#ffffff",
  },
  button: {
    color: "#007bff",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
  },
};

export default ItemsTable;
